<template>
    <!-- v-click-outside="isOpen=false" -->
    <div class="relative inline-flex" ref="dropdown"   @click="isOpen=true" >
        <slot></slot>
      
        <div class="pt-2" >
            <div v-if="isOpen"  :class="[`mt-${props.marginTop}`,`dropdown-list absolute left-0  flex z-20 group w-52 py-2 px-1 text-sm text-gray-700  rounded-lg border bg-white transition-all`]">
             <slot name="item"></slot>
           </div>
        </div>
  
    </div>
  </template>
  
<script setup>
// Reactive state
const isOpen = ref(false);

const props=defineProps(["marginTop"])

// Reference to the dropdown element
const dropdown = ref(null);

// Function to close the dropdown if clicked outside
const closeDropdownOnOutsideClick = (event) => {
if (dropdown.value && !dropdown.value.contains(event.target)) {
isOpen.value = false;
}
};

// Add event listener on mount and clean up on unmount
onMounted(() => {
document.addEventListener('click', closeDropdownOnOutsideClick);
});
</script>